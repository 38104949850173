<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/3.jpg') center center;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Τιμοκατάλογος </h4>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Orderify</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item active" aria-current="page">Pricing</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Price Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Διαθέσιμα Πακέτα</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Το <span class="text-primary fw-bold">Orderify </span> κοστίζει
            λιγότερο από 1.5€ την μέρα.
            Ξεκινήστε από 39€ τον μήνα.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-12 mt-4 pt-2">
        <div class="text-center">
          <ul ngbNav
              #nav="ngbNav"
              class="nav nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2"
              id="pills-tab"
              role="tablist">
            <li ngbNavItem
                class="nav-item d-inline-block">
              <a ngbNavLink
                 class="nav-link px-3 rounded-pill">
                Μηνιαίο Πλάνο
              </a>
              <ng-template ngbNavContent>
                <app-shared-pricing [pricingData]="monthlyData"></app-shared-pricing>
              </ng-template>
            </li>
            <li ngbNavItem
                class="nav-item d-inline-block">
              <a ngbNavLink
                 class="nav-link px-3 rounded-pill">
                Εξάμηνο Πλάνο <span class="badge rounded-pill bg-success">5% Off </span>
              </a>
              <ng-template ngbNavContent>
                <app-shared-pricing [pricingData]="halfYearData"></app-shared-pricing>
              </ng-template>
            </li>
            <li ngbNavItem
                class="nav-item d-inline-block">
              <a ngbNavLink
                 class="nav-link px-3 rounded-pill">
                Ετήσιο Πλάνο <span class="badge rounded-pill bg-success">10% Off </span>
              </a>
              <ng-template ngbNavContent>
                <app-shared-pricing [pricingData]="yearlyData"></app-shared-pricing>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"
               class="col-12"></div>
        </div>
      </div>
      <!--end col-->
    </div>

    <div class="row justify-content-center pt-4">
      <div class="text-center">
        <!-- <p class="text-muted para-desc mb-0 mx-auto pb-1">Η προσφορά ισχύει μέχρι 10/10!</p> -->
        <p class="text-muted para-desc mb-0 mx-auto pb-1">Η απόκτηση των Πακέτων προϋποθέτει την αγορά της βασικής συνδρομής.</p>
        <p class="text-muted"
           style="font-size: 75%;">
          Οι εμφανιζόμενες τιμές αφορούν συνολική εξόφληση και είναι τελικές (<strong>χωρίς</strong> επιπλέον χρεώσεις & ΦΠΑ).
        </p>
      </div>
    </div>
  </div>
  <!--end container-->

</section>
<!--end section-->
<!-- Testi End -->

<!-- FAQ n Contact Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="d-flex">
          <i-feather name="help-circle"
                     class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">Πως λειτουργεί η δωρέαν περίοδος των 7 ημερών και η δοκιμαστική περίοδος του ενός μηνός;
            </h5>
            <p class="answer text-muted mb-0">
              Με την εγγραφή σας στο Orderify και την δημιουργία του καταστήματος
              σας έχετε άμεση πρόσβαση στην πλήρη έκδοση της εφαρμογής μας για 7 ημέρες. Στην συνέχεια, αν αγοράσετε
              κάποιο πακέτο αλλά για κάποιο λόγο αποφασίσετε να μην συνεχίσετε
              να χρησιμοποιείτε την υπηρεσία μας, σας δίνουμε την δυνατότητα ακύρωσης της συνδρομής και πλήρη
              επιστροφή των χρημάτων σας μέσα στο διάστημα του πρώτου μήνα. Δεν κάνουμε επιπλέον ερωτήσεις.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex">
          <i-feather name="help-circle"
                     class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">Τι περιλαμβάνει το κόστος συνδρομής;</h5>
            <p class="answer text-muted mb-0">Η συνδρομή στην υπηρεσία μας περιλαμβάνει την πρόσβαση στις εφαρμογές μας
              από όλες τις συσκευές σας μέσω του cloud,
              τη φιλοξενία των δεδομένων σας, τη νομική συμμόρφωση της εφαρμογής, συμβουλευτικές και εκπαιδευτικές
              υπηρεσίες, την τεχνική υποστήριξη για θέματα της εφαρμογής
              και φυσικά όλες τις αναβαθμίσεις των εφαρμογών.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle"
                     class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">Με ποιά κριτήρια έχει οριστεί το αντίτιμο της υπηρεσίας;</h5>
            <p class="answer text-muted mb-0">Έχουμε εκπονήσει μελέτη Εκτίμησης Αντικτύπου Προσωπικών Δεδομένων και
              έχουμε υπολογίσει προσεκτικά το κόστος εργασίας
              μας έτσι ώστε να σας παρέχουμε μία ασφαλή εφαρμογή και μία αξιόπιστη και μακρόπνοη συνεργασία. Στο κόστος
              μας έχουμε συμπεριλάβει τη συμμόρφωση με το
              ισχύον νομικό και κανονιστικό πλαίσιο, τη διαφάνεια και τον ποιοτικό έλεγχο των συστημάτων μας.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle"
                     class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">Πως μπορώ να αγοράσω την υπηρεσία σας;</h5>
            <p class="answer text-muted mb-0">Κάντε την <a href="https://admin.orderify.gr/register"
                 target="blank"
                 class="text-primary">εγγραφή</a> σας στο σύστημα μας, και ξεκινήστε
              να χρησιμοποιήσετε την εφαρμογή μας άμεσα και με δυνατότητα για πλήρη επιστροφή των χρημάτων σας μέσα
              στον πρώτο μήνα.
              Μπορείτε να εξοφλείτε τη συνδρομή σας, μέσω της χρεωστικής ή πιστωτικής κάρτας σας VISA, MasterCard,
              Maestro, Diners, American Express
              ή με μεταφορά του ποσού σε τραπεζικό λογαριασμό μας.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Έχετε άλλες απορίες; Επικοινωνήστε μαζί μας!</h4>
          <p class="text-muted para-desc mx-auto">Για όλους εμάς στο <span class="text-primary fw-bold">Orderify</span>
            είναι μεγάλη μας χαρά να σας γνωρίσουμε και να μιλήσουμε μαζί σας.</p>
          <div class="mt-4 pt-2">
            <a routerLink="/contact"
               class="btn btn-primary"><i class="mdi mdi-phone"></i> Επικοινωνία</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- FAQ n Contact End -->

<!-- Back to top -->
<a href="javascript: void(0);"
   class="btn btn-icon btn-primary back-to-top"
   id="back-to-top"
   [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up"
             class="icons"></i-feather>
</a>
<!-- Back to top -->