<!-- Hero Start -->
<section class="bg-home bg-light d-flex align-items-center"
style="background: url('assets/images/onepage/bg.png') center center; height: auto;" id="home">
<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
      <div class="title-heading margin-top-100">
        <h1 class="heading mb-3" data-aos="fade-up" data-aos-duration="1000">Υπηρεσίες Παραγγελιοληψίας
          <span class="text-primary">Orderify</span>
        </h1>
        <p class="para-desc mx-auto text-muted" data-aos="fade-up" data-aos-duration="1400">Το Orderify είναι ένα ολοκληρωμένο πακέτο παροχής υπηρεσιών που αφορούν την διαχείριση παραγγελιών.
          Είναι σχεδιασμένο έτσι ώστε να δίνει στον καταστηματάρχη την καλύτερη δυνατή λύση που του ταιριάζει, προσφέροντας 
          ταυτόχρονα και υπηρεσίες στους πελάτες του με στόχο την άμεση εξυπηρέτηση τους.</p>
        <!-- <div class="text-center subcribe-form mt-4 pt-2" data-aos="fade-up" data-aos-duration="1800">
          <form>
            <div class="form-group mb-0">
              <input type="email" id="email" name="email" class="shadow bg-white rounded-pill" required=""
                placeholder="Email Address">
              <button type="submit" class="btn btn-pills btn-primary">Get Started</button>
            </div>
          </form>
        </div> -->
      </div>

      <div class="home-dashboard onepage-hero" data-aos="fade-up" data-aos-duration="2200">
        <img src="assets/images/orderify/dashboard-new.png" height="300" alt="Dashboard" class="img-fluid main-img">
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Features Start -->
<section class="section pb-0" id="service">
<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Χαρακτηριστικά που ξεχωρίζουν</h4>
        <p class="text-muted para-desc mx-auto mb-0" data-aos="fade-up" data-aos-duration="1400">Ανακαλύψτε τους λόγους που καθιστούν το 
          <span class="text-primary fw-bold">Orderify</span> την πιο ανταγωνιστική και ολοκληρωμένη λύση παραγγελιοληψίας στην αγορά.
        </p>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
  <app-services [servicesData]="servicesData"></app-services>
</div>
<!--end container-->

<div class="container mt-100 mt-60">
  <div class="p-4 rounded bg-light shadow" data-aos="fade-up" data-aos-duration="1400">
    <div class="row align-items-center">
      <div class="col-sm-7">
        <div class="text-sm-left">
          <h5 class="mb-0">Ξεκινήστε τώρα με την δωρέαν δοκιμή σας.</h5>
        </div>
      </div>
      <!--end col-->

      <div class="col-sm-5 mt-4 mt-sm-0">
        <div class="text-sm-end">
          <a href="https://admin.orderify.gr/register" target="blank" 
            class="btn btn-outline-primary">Δοκιμάστε το
            <!-- <span class="badge rounded-pill bg-danger ms-2">v2.1</span> -->
          </a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row -->
  </div>
</div>
<!--end container-->

<!-- <div class="container mt-100 mt-60">
  <div class="row">
    <div class="col-12">
      <div class="position-relative" style="z-index: 1;" data-aos="zoom-in" data-aos-duration="1600">
        <img src="assets/images/orderify/admin-laptop.png" class="rounded img-fluid mx-auto d-block" alt=""> -->
        <!-- <div class="play-icon">
          <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn video-play-icon">
            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
          </a>
          <ng-template #content let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
        </div> -->
      <!-- </div> -->
    <!-- </div> -->
  <!-- </div> -->
  <!--end row-->
<!-- </div> -->
<!--end container-->
</section>
<!--end section-->
<!-- <div class="position-relative">
<div class="shape overflow-hidden text-light">
  <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
  </svg>
</div>
</div> -->
<!-- Features End -->

<!--
<section class="bg-half-170 d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading">
          <h1 class="heading mb-3">Manage all of you stuff using <span class="text-primary">Landrick.</span> app</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4">
          <img src="assets/images/orderify/customer.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
-->
<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Features Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Orderify Customer</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Δώστε την ευκαιρία στους πελάτες σας να παραγγείλουν από την δική τους συσκευή, με την εγγύηση του
            <span
              class="text-primary fw-bold">Orderify</span>.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center align-items-center">
      <div class="col-lg-8 col-md-8">
        <div class="row mt-4 pt-2">
          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="clock" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Ενημέρωση σε πραγματικό χρόνο</h4>
                <p class="text-muted para mb-0">Ενημερωθείτε σε πραγματικό χρόνο για τις παραγγελίες που πραγματοποιούνται από τους πελάτες σας και δράστε άμεσα.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="smile" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Κάλεσμα σερβιτόρου</h4>
                <p class="text-muted para mb-0">Οι σερβιτόροι είναι πάντα διαθέσιμοι στους πελάτες σας με το πάτημα ενός κουμπιού.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="shopping-bag" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Χωρίς έξτρα χρεώσεις</h4>
                <p class="text-muted para mb-0">Επιλέγοντας το πακέτο Πελατών έχετε όλες τις δυνατότητες που σας προσφέρει η εφαρμογή.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="coffee" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Παραμετροποιήσιμο μενού</h4>
                <p class="text-muted para mb-0">Ευκολία παραμετροποίησης του μενού για τους πελάτες σας.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Άψογη σχεδίαση</h4>
                <p class="text-muted para mb-0">Σχεδιασμένο για χρήση σε οποιαδήποτε συσκευή (Υπολογιστές, κινητά και tablets).</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="trending-up" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Ευκολία χρήσης</h4>
                <p class="text-muted para mb-0">Οι πελάτες σας θα είναι σε θέση να παραγγείλουν σε 3 απλά βήματα χωρίς πολύπλοκες διαδικασίες.</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
        <img src="assets/images/orderify/customer.png" class="img-fluid" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Feature End -->

<!-- Showcase Start -->
<section class="section pt-0 bg-light">
  <div class="container mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Εφαρμογές <span class="text-primary">Orderify</span></h4>
          <p class="text-muted para-desc mb-0 mx-auto">Ανακαλύψτε όλες τις εφαρμογές του <span
              class="text-primary fw-bold">Orderify</span> με τις οποίες μπορείτε να μεγιστοποιήσετε την αποδοτικότητα της επιχείρησης σας.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class=" mt-4 pt-2 text-center">
        <ul ngbNav #nav="ngbNav"
          class="nav-pills nav-justified flex-column flex-sm-row rounded col-lg-8 col-md-12 offset-md-2">
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                <h6 class="mb-0">Orderify Waiter</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/orderify/waiter1.png" class="img-fluid mx-auto d-block" style="width: 300px;" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title text-md-start">
                    <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> Η πιο ολοκληρωμένη εφαρμογή παραγγελιοληψίας</h4>
                    <p class="text-muted">Η εφαρμογή <span class="text-primary">Orderify Waiter</span> απευθύνεται στους σερβιτόρους των καταστημάτων, παρέχοντας τους τις
                      συνήθεις δυνατότες μιας τέτοιας εφαρμογής μαζί με αρκετές πρωτοποριακές προσθήκες, σε ένα αξιόπιστο περιβάλλον.</p>
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Δυνατότητα διαχείρισης εμφάνισης καταλόγου και προϊόντων</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Διαχείριση πόστων και τραπεζιών</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Χωρίς δέσμευση λογαριασμών και συσκευών</li>
                    </ul>
                    <a href="https://play.google.com/store/apps/details?id=com.orderify.Waiter" target="blank" class="btn btn-primary m-1"><i class="uil uil-google-play"></i> Play
                      Store</a>
                    <a href="https://apps.apple.com/gr/app/orderify-waiter/id6446465184?l=el" target="blank" class="btn btn-outline-primary m-1"><i class="uil uil-apple"></i> Apple
                      Store</a>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                <h6 class="mb-0">Orderify KDS</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/orderify/kds1.png" class="img-fluid mx-auto d-block" style="width: 300px;" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title text-md-start">
                    <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> Η απόλυτη διαχείριση παραγγελιών</h4>
                    <p class="text-muted">Το <span class="text-primary">Orderify KDS</span> είναι το πάσο σας. Μπορείτε να το λειτουργήσετε
                    είτε αυτόνομα είτε παράλληλα με κάποιον θερμικό εκτυπωτή για την μέγιστη διαχείριση των παραγγελιών σε κάθε πόστο.</p>
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Εμφάνιση και διαχείριση παραγγελιών</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Διαχείριση εκτυπώσεων παραγγελιών στον θερμικό εκτυπωτή</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Δυνατότητα πολλαπλών συσκευών</li>
                    </ul>
                    <a href="https://play.google.com/store/apps/details?id=com.orderify.kds&hl=el" target="blank" class="btn btn-primary m-1"><i class="uil uil-google-play"></i> Play
                      Store</a>
                    <a href="https://apps.microsoft.com/detail/9nh7jg5skgmp?hl=el-GR&gl=GR" target="blank" class="btn btn-outline-primary m-1"><i class="uil uil-microsoft"></i> Microsoft
                      Store</a>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
          <!-- <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                <h6 class="mb-0">Orderify Pocket</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/orderify/pocket-white.png" class="img-fluid mx-auto d-block" alt="">
                </div>

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title text-md-start">
                    <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> Το κατάστημα σας, τώρα και στην τσέπη σας</h4>
                    <p class="text-muted">Διαχειριστείτε από το κινητό το κατάστημα σας και δείτε μια ζωντανή εικόνα του ανα πάσα στιγμή όπου και αν βρίσκεστε.</p>
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Απομακρυσμένη διαχείριση του καταστήματος σας</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Εμφάνιση και διαχείριση των βαρδιών</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Προβολή στατιστικών σε πραγματικό χρόνο</li>
                    </ul>
                    <a href="https://play.google.com/store/apps/details?id=com.orderify.Pocket" target="blank" class="btn btn-primary m-1"><i class="uil uil-google-play"></i> Play
                      Store</a>
                    <a  class="btn btn-outline-primary m-1"><i class="uil uil-apple"></i> Apple
                      Store (coming soon)</a>
                  </div>
                </div>  
              </div>
            </ng-template>
          </li> -->
        </ul>
        <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
      </div>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Showcase End -->

<!-- Features Start -->
<section class="section pb-5" id="service">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Ηλεκτρονική Τιμολόγηση</h4>
          <p class="text-muted para-desc mx-auto mb-0" data-aos="fade-up" data-aos-duration="1400">Επωφεληθείτε άμεσα από την ηλεκτρονική τιμολόγηση που σας παρέχει η
            <span class="text-primary fw-bold">Orderify</span> με την πιο ολοκληρωμένη λύση στις πιο ανταγωνιστικές τιμές της αγοράς.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-services [servicesData]="invoicingData"></app-services>
  </div>
  <!--end container-->
</section>
<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->