<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100"
         id="home"
         style="background: url('assets/images/contact-detail.jpg') center center;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Επικοινωνήστε μαζί μας!</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section pb-0">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-phone d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">Τηλέφωνο</h5>
            <a href="tel:+302102204513"
               class="text-primary"
               onclick="return gtag_report_conversion('tel:2102204513');">210-220-4513</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">Email</h5>
            <a href="mailto:support@orderify.gr"
               class="text-primary">{{"support@orderify.gr"}}</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">Διεύθυνση</h5>
            <a (click)="mapView(content)"
               href="javascript:void(0);"
               data-type="iframe"
               class="video-play-icon text-primary">Προβολή στον χάρτη</a>
            <ng-template #content
                         let-modal>
              <div class="modal-header">
                <button type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.3893988670534!2d23.76748597715363!3d37.897960005052745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bc6a0199cb255b%3A0xe029a129474d618b!2sOrderify!5e0!3m2!1sel!2sgr!4v1713006923417!5m2!1sel!2sgr"
                        width="600"
                        height="450"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">Φόρμα Επικοινωνίας</h4>
            <div class="custom-form mt-3">
              <div [formGroup]="contactForm">
                <p id="error-msg"
                   class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Ονοματεπώνυμο<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user"
                                   class="fea icon-sm icons"></i-feather>
                        <input id="name"
                               type="text"
                               class="form-control ps-5"
                               formControlName="name"
                               placeholder="Το ονοματεπώνυμο σας:">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Email<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail"
                                   class="fea icon-sm icons"></i-feather>
                        <input id="email"
                               type="email"
                               class="form-control ps-5"
                               formControlName="email"
                               placeholder="Το Email σας:">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Τηλέφωνο</label>
                      <div class="form-icon position-relative">
                        <i-feather name="phone"
                                   class="fea icon-sm icons"></i-feather>
                        <input id="subject"
                               class="form-control ps-5"
                               formControlName="phone"
                               placeholder="Τηλέφωνο επικοινωνίας:">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Μήνυμα<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle"
                                   class="fea icon-sm icons clearfix"></i-feather>
                        <textarea id="comments"
                                  rows="4"
                                  class="form-control ps-5"
                                  formControlName="text"
                                  placeholder="Το μήνυμα σας:"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid">
                      <button mat-raised-button
                              type="button"
                              *ngIf="!isSending"
                              id="submit-btn"
                              [disabled]="isSending"
                              (click)="sendEmail()"
                              class="btn btn-primary">Αποστολή</button>
                      <button mat-raised-button
                              type="button"
                              *ngIf="isSending"
                              id="submit-btn"
                              [disabled]="true"
                              (click)="sendEmail()"
                              class="btn btn-primary">
                        <mat-spinner style="margin:0 auto;"
                                     diameter="25"></mat-spinner>
                      </button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="card border-0">
          <div class="card-body p-0">
            <img src="assets/images/contact.svg"
                 class="img-fluid"
                 alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container-fluid mt-100 mt-60">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.3893988670534!2d23.76748597715363!3d37.897960005052745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bc6a0199cb255b%3A0xe029a129474d618b!2sOrderify!5e0!3m2!1sel!2sgr!4v1713006923417!5m2!1sel!2sgr"
                    width="750"
                    height="450"
                    style="border:0;"
                    allowfullscreen="true"
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);"
   class="btn btn-icon btn-primary back-to-top"
   id="back-to-top"
   [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up"
             class="icons"></i-feather>
</a>
<!-- Back to top -->